<template>
	<div class="error">
		Error 404 Not Found
	</div>
</template>

<script>
export default {
	name: 'ErrorNotFound',
}
</script>
