<template>
	<AppNavigation/>
	<router-view/>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue';

export default {
	components: {
		AppNavigation,
	}
}
</script>

<style>

</style>
