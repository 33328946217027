<template>
	<div class="container">
		<div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
			<div class="col-span-full">
				<h2>Internet Tools</h2>
			</div>

			<AppToolPanel title="Domains" :links="[
				{ label: 'DNS Lookup', route: 'dns-lookup' },
				{ label: 'Reverse Lookup', route: 'dns-reverse' },
			]">
				Lookup domain names and PTR records.
			</AppToolPanel>

			<AppToolPanel title="Numbers" :links="[
				{ label: 'IP Lookup', route: 'ip-lookup' },
				{ label: 'CIDR Calculator', url: 'cidr-calculator' },
				{ label: 'ASN Lookup', url: 'asn-lookup' },
			]">
			</AppToolPanel>

			<div class="col-span-full">
				<h2>Calculators</h2>
			</div>

			<AppToolPanel title="Time" :links="[
				{ label: 'Time Ago', url: '' },
			]">
			</AppToolPanel>

			<div class="col-span-full">
				<h2>Other Tools</h2>
			</div>

			<AppToolPanel title="WHOIS" :links="[
				{ label: 'ICANN - Whois Lookup', url: 'https://lookup.icann.org/' },
				{ label: 'CIRA - Contact a Domain Holder', comment: 'Send a message to the holder of a privately registered .CA domain.', url: 'https://www.cira.ca/ca-domains/contact-a-domain-holder'}
			]">
				<p>Since the data contained in a WHOIS lookup could contain personal information, this site does not offer a tool for querying WHOIS records. Instead, the ICANN tool can be used to lookup the registration information for a domain name.</p>
			</AppToolPanel>

			<AppToolPanel title="RIR RDAP Services" :links="[
				{ label: 'ARIN', comment: 'American Registry for Internet Numbers', url: 'https://www.arin.net/resources/registry/whois/rdap/' },
				{ label: 'RIPE NCC', comment: 'Réseaux IP Européens Network Coordination Centre', url: 'https://www.ripe.net/manage-ips-and-asns/db/registration-data-access-protocol-rdap' },
				{ label: 'LACNIC', comment: 'Latin America and Caribbian Network Information Center', url: 'https://rdap-web.lacnic.net/rdap/about/' },
				{ label: 'AFRINIC', comment: 'Asia-Pacific Network Information Centre', url: 'https://afrinic.net/whois/rdap/' },
				{ label: 'APNIC', comment: 'African Network Information Center', url: 'https://www.apnic.net/about-apnic/whois_search/about/rdap/' },
			]">
				<p>
					Each <a href="https://en.wikipedia.org/wiki/Regional_Internet_registry">Regional Internet Registry (RIR)</a>
					operates a <a href="https://en.wikipedia.org/wiki/Registration_Data_Access_Protocol">Registration Data Access Protocol (RDAP)</a>
					service that provides information about the networks assigned to them.
				</p>
			</AppToolPanel>
		</div>
	</div>
</template>

<script>
import AppToolPanel from '@/components/AppToolPanel.vue';
export default {
	name: 'Home',
	components: {
		AppToolPanel,
	}
}
</script>
