<template>
	<div class="p-4 bg-gray-100 rounded">
		<h3>{{ title }}</h3>
		
		<slot></slot>

		<div class="my-4 border-b border-black"></div>

		<ul class="">
			<li v-for="link in links" v-bind:key="link.label">
				<router-link v-if="link.route" :to="{ name: link.route }">{{ link.label }}</router-link>
				<a v-else-if="link.url" :href="link.url" target="_blank" rel="nofollow noopener">{{ link.label }}</a>
				<span v-if="link.comment" class="text-gray-600"> - {{ link.comment }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'AppToolPanel',
	props: {
		title: String,
		links: Array,
	}
}
</script>