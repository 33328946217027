import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import ErrorNotFound from '../views/ErrorNotFound.vue'

const routes = [
  {
    name: 'home',
    path: '/',
    
    component: Home,
  },
  {
    name: 'about',
    path: '/about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    name: 'dns-lookup',
    path: '/dns/lookup/:name?/:type?',
    props: true,
    component: () => import(/* webpackChunkName: "dns.lookup" */ '../views/dns/Lookup.vue'),
  },
  {
    name: 'dns-reverse',
    path: '/dns/reverse/:name?',
    props: true,
    component: () => import(/* webpackChunkName: "dns.lookup" */ '../views/dns/Reverse.vue'),
  },
  {
    name: 'ip-lookup',
    path: '/ip/lookup/:ip?',
    props: true,
    component: () => import(/* webpackChunkName: "ip.lookup" */ '../views/ip/Lookup.vue'),
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: ErrorNotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
