<template>
	<nav id="nav" class="mb-4 bg-gray-100">
		<div class="container">
			<ul class="flex flex-wrap gap-4 p-4">
				<li><router-link :to="{ name: 'home' }">Home</router-link></li>
				<li><router-link :to="{ name: 'about' }">About</router-link></li>
			</ul>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'AppNavigation',
}
</script>